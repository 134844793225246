<template>
  <div v-loading="loading"
    element-loading-text="loading..."
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
    <div>
      <el-breadcrumb separator=">">
        <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/search' }">Search</el-breadcrumb-item>
      </el-breadcrumb>
     <!-- <h3 class="inform-upgrade">The search function is undergoing upgrade!</h3>-->
      <div class="search-container">
        <div class="search-box">
          <!-- <div class="left-box">
            <el-card shadow="hover">
              <el-checkbox v-model="disease" label="disease">Disease</el-checkbox>
              <el-checkbox v-model="snp" label="snp">SNP</el-checkbox>
              <el-checkbox v-model="gene" label="gene">Gene</el-checkbox>
              <el-checkbox v-model="ppi" label="ppi">PPi</el-checkbox>
              <el-checkbox v-model="pathway" label="pathway">Pathway</el-checkbox>
            </el-card>
          </div> -->
          <div class="right-box">
            <el-card shadow="hover">
            <el-form
              label-position="right"
              label-width="80px"
              :model="formLabelAlign"
            >
              <el-form-item label="Disease">
                  <!-- <el-input v-model="formLabelAlign.disease" :disabled="!disease"></el-input> @select="handleSelect"-->
                  <el-autocomplete
                    class="inline-input"
                    placeholder="Input disease, eg: E66 Obesity"
                    :fetch-suggestions="querySearch"
                    :trigger-on-focus="false"
                    v-model="formLabelAlign.disease"
                    @select="handleSelect"
                  ></el-autocomplete>
              </el-form-item>
              <el-form-item><h4>Filter by:</h4></el-form-item>
              <el-form-item label="SNP">
                  <el-input class="inline-input" type="textarea" :rows="1" v-model="formLabelAlign.snp" placeholder="Input SNP IDs, comma separated"></el-input>
                <span class="notice-example">example: rs146683910,rs28895131,rs28895067</span>
              </el-form-item>
              <el-form-item label="Gene">
                  <el-input class="inline-input" type="textarea" :rows="1" v-model="formLabelAlign.gene" placeholder="Input gene entrez IDs, comma separated"></el-input>
                <span class="notice-example">example: 1041,10554,10665</span>
              </el-form-item>
              <el-form-item label="PPI">
                  <el-input class="inline-input" type="textarea" :rows="2" v-model="formLabelAlign.ppi" placeholder="Input PPIs with the format of entrez ID~entrez ID, comma separated"></el-input>
                <span class="notice-example">example: 2100~55037,57819~6633,9577~9656</span>
              </el-form-item>
              <el-form-item label="Pathway">
                  <!--<el-input type="textarea" :rows="1" v-model="formLabelAlign.pathway" placeholder="Input MSigDB pathways, comma separated"></el-input>-->
                <el-autocomplete
                    class="inline-input"
                    placeholder="Input MSigDB pathways, comma separated"
                    :fetch-suggestions="querySearchPathway"
                    v-model="formLabelAlign.pathway"
                    :trigger-on-focus="false"
                    @select="handleSelectPathway"
                ></el-autocomplete>
                <span class="notice-example">example: kegg cell adhesion molecules cams,reactome tcr signaling,pid il12 2pathway</span>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="onSubmit">submit</el-button>
              </el-form-item>
            </el-form>
            </el-card>
          </div>
        </div>
        <div class="result-box" ref="resultBox">
          <el-card shadow="hover">
          <div class="relationmap">
            <relationmap
              :browseData="browseData"
              relationMapId="relationmap-search"
              :searchDisease="formLabelAlign.disease.split(',')"
            ></relationmap>
          </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import relationmap from "../components/charts/searchRelationship";
import { searchComorbidity } from "../api/search";
import {getDisease, getPathWay} from '../data/autoComplete'
export default {
  data: () => {
    return {
      formLabelAlign: {
        disease: '',
        diseaseArr: new Set(),
        snp: '',
        gene: '',
        ppi: '',
        pathway: '',
        pathwayArr: [],
      },
      browseData: {},
      loading: false,
    };
  },
  components: {
    relationmap: relationmap,
  },
  methods: {
    async querySearch(queryString, cb) {
      if(queryString.length > 0) {
        queryString = queryString.split(',').pop();
      }
      let res = queryString
        ? await getDisease(queryString)
        : null;
      if(res.length === 0) {
        console.log("返回的数组为空");
      }
      cb(res);
      let lastInputStr = this.formLabelAlign.disease.split(',').slice(0,-1).join(',');
      if(Array.from(this.formLabelAlign.diseaseArr).join(',') !== lastInputStr){
        this.formLabelAlign.diseaseArr = new Set(lastInputStr.split(','));
      }
    },
    async querySearchPathway(queryString, cb) {
      // 输入的为queryString，返回的是result,cb(result)
      // 这里需要进行查询，返回搜索到的结果。
      // let resultArr = [];
      if(queryString.length > 0) {
        queryString = queryString.split(',').pop();
      }
      let res = queryString
          ? await getPathWay(queryString)
          : null;
      if(res.length === 0) {
        console.log("返回的数组为空");
      }
      cb(res);
      let lastInputStr = this.formLabelAlign.pathway.split(',').slice(0,-1).join(',');
      if(Array.from(this.formLabelAlign.pathwayArr).join(',') !== lastInputStr){
        this.formLabelAlign.pathwayArr = new Set(lastInputStr.split(','));
      }
    },
    handleSelect(item) {
      let name = item.value;// 获得点选的疾病id，接着就获得关系图所需数据
      this.formLabelAlign.diseaseArr.add(name);
      this.formLabelAlign.disease = Array.from(this.formLabelAlign.diseaseArr).join(',') + ','
      if(this.formLabelAlign.disease.startsWith(',')) {
        this.formLabelAlign.disease=this.formLabelAlign.disease.slice(1);
      }
    },
    handleSelectPathway(item) {
      let name = item.value;// 获得点选的疾病id，接着就获得关系图所需数据
      this.formLabelAlign.pathwayArr.push(name);
      this.formLabelAlign.pathway = this.formLabelAlign.pathwayArr.join(',') + ',';
      if(this.formLabelAlign.pathway.startsWith(',')) {
        this.formLabelAlign.pathway=this.formLabelAlign.pathway.slice(1);
      }
    },
    async onSubmit() {
      this.loading = true;
      this.$refs.resultBox.style.display = "none";
      let res = await searchComorbidity({
        name: !this.formLabelAlign.disease.trim() ? null : this.formLabelAlign.disease.trim(),
        snp: !this.formLabelAlign.snp.trim() ? null : this.formLabelAlign.snp.trim(),
        ppi: !this.formLabelAlign.ppi.trim() ? null : this.formLabelAlign.ppi.trim(),
        gene: !this.formLabelAlign.gene.trim() ? null :this.formLabelAlign.gene.trim(),
        pathway: !this.formLabelAlign.pathway.trim() ? null : this.formLabelAlign.pathway.trim(),
      });
      if(res.browseDataDto.node.length > 0) {
        this.browseData = res.browseDataDto;
        this.$refs.resultBox.style.display = "block";
      } else {
        this.$message({
          showClose: true,
          message: 'no matched data!',
          type: 'warning'
        });
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="less">
  .el-form-item{
    margin-bottom: 12px;
  }


  .search-container{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
.search-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;

  flex-basis: 40%;
  .left-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    .el-checkbox {
      //display: block;
      width: 106px;
      text-align: center;
      margin: 0 0 20px;
    }
  }
  .right-box {
    flex-basis: 50%;
    h4 {
      line-height: 15px;
      text-align: left;
      margin: 0;
    }
  }
}
.result-box {
  padding: 20px;
  flex-basis: 60%;
  //min-height: 500px;
  display: none;
}
.relationmap {
  min-height: 500px;
  //border: 1px solid #ccc;
}

.inform-upgrade{
    margin: 10px 0 0;
    //text-shadow: 1px 1px 2px #999999;
    font-style: italic;
    color: #662020;
    font-size: 18px;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, PingFang SC,
    Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica, Arial, sans-serif;
  }
  .notice-example{
    line-height: 25px;
    color:#aaaaaa;
    display: block;
    text-align: left;
  }
  .inline-input{
    width: 100%;
  }
</style>