<template>
  <div v-loading="loading">
    <div
      :id="relationMapId"
      style="min-height: 500px"
    ></div>
  </div>
</template>

<script>
import {colorObj} from '../../data/realtionmap'
var echarts = require("echarts");
export default {
  props: {
    relationMapId: {
      type: String,
      default: "",
    },
    browseData: {
      type: Object,
      default: () => {
        return {
          description: "",
          disease: "",
          node: [],
          edge: []
        }
      }
    },
    searchDisease: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      categories: [],
      graph: { nodes: [], links: [] },
      colorObj,
      myChart: null,
      loading: true,
      categoryArr: [],
    };
  },
  computed: {
    option() {
      return {
        color: [],
        tooltip: {
          formatter: (params) => {
            if (params.dataType === "node") {
              return `
              <div style="text-align: left;">
                code: ${params.value.code}<br />
                name: ${params.value.name}<br />
                comorCount: ${params.value.comorCount}<br />
                prevalence: ${(params.value.prevalence * 100).toFixed(2)}%<br />
                female: ${params.value.sexRatio * 100}%<br />
                male: ${((1 - params.value.sexRatio) * 100).toFixed(2)} %<br />
                rr_avg: ${params.value.rrAvg ? params.value.rrAvg.toFixed(6) : 'null'}<br />
                rrMax: ${params.value.rrMax}<br />
                rrMin: ${params.value.rrMin}<br />
              </div>
              `;
            } else if (params.dataType === "edge") {
              let { rrValue, cijIi, cijIj, cijsimIi, cijsimIj } = JSON.parse(
                params.data.name
              );
              return `
                <div style="text-align: left;">
                  rr-value: ${rrValue}<br />
                  cij-ii: ${cijIi}<br />
                  cij-ij: ${cijIj}<br />
                  cijsim-ii: ${cijsimIi}<br />
                  cijsim-ij: ${cijsimIj}<br />
                </div>
              `;
            }
          },
        },
        legend: [
          {
            data: this.categories,
            top: "10%",
            left: "right",
            orient: "vertical",
            bottom: 20,
            type: "scroll",
          },
        ],
        toolbox: {
          show: true,
          feature: {
            saveAsImage: {
              title: "save as image",
            },
          },
        },
        animationDuration: 1500,
        animationEasingUpdate: "quinticInOut",
        series: [
          {
            name: "Les Miserables",
            type: "graph",
            layout: "none",
            data: this.graph.nodes,
            links: this.graph.links,
            categories: this.categories,
            roam: true,
            focusNodeAdjacency: true,
            itemStyle: {
              borderColor: "#fff",
              borderWidth: 1,
              shadowBlur: 10,
              shadowColor: "rgba(0, 0, 0, 0.3)",
            },
            label: {
              //show:true,
              position: "top",
              formatter: "{b}",
            },
            lineStyle: {
              color: "#bbb",
              //curveness: 0.3,
              width: 1,
            },
            emphasis: {
              lineStyle: {
                width: 5,
              },
            },
          },
        ],
      };
    },
  },
  watch: {
    browseData: {
      handler(newValue) {
        if (newValue.node.length > 0) {
          this.handleBrowseData(newValue);
        } else {
          this.loading = true;
        }
      },
    }
  },
  methods: {
    refreshMap() {
      this.loading = false;
      this.myChart.resize();
      this.myChart.setOption(this.option);
    },
    innerCircle(name) {
        // this.searchDisease是包含所有输入的疾病的数组
        // 如果当前的点的code在数组中能找到，那么就表示它的坐标应该是内圈
        let R = 200, r = 50;
        if(this.searchDisease.indexOf(name) > -1) {
            return r;
        } else {
            return R;
        }
    },
    getRandom(name, category, categoryArr) {
      let angle = ((Math.PI * 2) / categoryArr.length) * category;
      let R = this.innerCircle(name);
      let x, y;
      let centerX = R * Math.cos(angle),
        centerY = R * Math.sin(angle);
      let random1 = Math.random(1),
        random2 = Math.random(1);
      let randomX = Math.random(1) * 80,
        randomY = Math.random(1) * 80;
      if (random1 < 0.5) {
        randomX = -randomX;
      }
      if (random2 < 0.5) {
        randomY = -randomY;
      }
      x = centerX + randomX;
      y = centerY + randomY;

      return [x,y];
    },
    handleBrowseData(browseData) {
      // 处理数据得到关系图的一些信息
      var result = {
        nodes: [],
        links: [],
      };
      var categoryArr = [];
      var category = null;
      browseData.node.forEach((node) => {
        let ind = categoryArr.indexOf(node.category);
        if (ind > -1) {
          category = ind;
        } else {
          categoryArr.push(node.category);
          category = categoryArr.length - 1;
        }
        let nodeObj = {
          // id: node.code,
          name: node.code,
          symbolSize: node.comor_count < 1 ? 2 : Math.sqrt(node.comor_count * 2),
          attributes: {
            modularity_class: category,
          },
          itemStyle: {
            borderColor: node.flag ? 'black' : '#fff'
          },
          x: null, //Math.random() * 100
          y: null, //Math.random() * 100,
          label: {
            show: node.flag,
          },
          value: {
            code: node.code,
            name: node.name,
            prevalence: node.prevalence,
            sexRatio: node["sex_ratio"],
            comorCount: node["comor_count"],
            rg: node["rg"],
            rrAvg: node["rr_avg"],
            rrMax: node["rr_max"],
            rrMin: node["rr_min"],
          },
          category: category,
        };
        result.nodes.push(nodeObj);
      });
      browseData.edge.forEach((link, index) => {
        let linkObj = {
          id: String(index),
          // name: null,
          source: link.source,
          target: link.destination,
          label: {
            show: false,
          },
          name: JSON.stringify({
            source: link.source,
            destination: link.destination,
            rrValue: link["rr"],
            cijIi: link["cij_ii"],
            cijIj: link["cij_ij"],
            cijsimIi: link["cijsim_ii"],
            cijsimIj: link["cijsim_ij"],
          }),
        };
        result.links.push(linkObj);
      });
      this.graph = result;
      result.nodes.forEach((node) => {
        let [x, y] = this.getRandom(node.value.name, node.category, categoryArr);
        node.x = x;
        node.y = y;
      });
      this.option.series[0].data = result.nodes;
      this.option.series[0].links = result.links;
      let colorArr = [];
      categoryArr = categoryArr.map((item, index) => {
        colorArr[index] = this.colorObj[item];
        return {
          name: item,
        };
      });
      this.categoryArr = categoryArr;
      this.option.series[0].color = colorArr;
      this.option.series[0].categories = categoryArr;
      this.option.legend[0].data = categoryArr;
      this.refreshMap();
    },
  },
  mounted() {
    this.myChart = echarts.init(document.getElementById(this.relationMapId));
    if (this.browseData) {
      this.handleBrowseData(this.browseData);
    }
  },
};
</script>
<style>
</style>